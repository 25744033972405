.project-page {
    .backLink {
        position: absolute;
        left: 20px;
        top: 20px;
    
        display: inline-flex;
        text-decoration: none;
        color: #fff;
        height: 25px;
        line-height: 25px;
    
        border: 1px solid #3dfaff;
        background-color: #3dfaff;
        color: #000;
        padding: 2px 10px;
        transition: all 0.2s ease;
    
        img {
            width: 20px;
            margin-right: 20px;
            filter: brightness(0%);
            transition: all 0.2s ease;
        }
    }
    
    .backLink:hover {
        background-color: #272727;
        color: #3dfaff;
    
        img {
            filter: brightness(100%);
        }
    }

    .paragraph:nth-child(1) {
        margin-bottom: 50px;
    }
    
    .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 15px;
        box-sizing: border-box;

        .image-gif {
            width: 800px;
            max-width: 100%;
        }
    
        h1 {
            margin-top: 80px;
            width: 100%;
            text-align: center;
        }

        h2 {
            margin-top: 50px;
            margin-bottom: 0;
            max-width: 800px;
            width: 100%;
            text-align: left;
            font-size: 1.2rem;
        }
    
        p {
            max-width: 800px;
            width: 100%;
            margin-bottom: 0;

            ul {
                margin: 0;
            }
        }
        p:last-of-type {
            margin-bottom: 50px;
        }

        .link {
            color: #3dfaff;
        }
        .link:visited {
            color: #3dfaff;
        }
    
        img {
            max-width: 100%;
            //width: 100%;
            margin: 20px;
            border: 1px solid rgba(255,255,255,0.1);
        }

        iframe {
            height: 180px;
            margin: 20px 0;
        }
    }
}

@media(min-width: 576px) {
    .project-page {
        .content {
            iframe {
                height: 480px;
            }
        }
    }
}