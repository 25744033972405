.H2Element {
    display: flex;
    justify-content: center;
    position: relative;

    h2 {
        display: inline-block;
        border-bottom: 2px;
        border-bottom-style: solid;
        position: relative;
        margin-bottom: 50px;
    }

    .futureborder {
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media(max-width: 768px) {
    .H2Element {
        h2 {
            margin-bottom: 10px;
        }
    }
}