.noMatch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    img {
        padding-bottom: 40px;
        width: 192px;
        max-width: 90%;
    }

    h1 {
        color: #fff;
        margin: 0 0 30px 0;
        text-align: center;
        font-size: 1.6rem;
        text-transform: uppercase;
        padding: 0 15px;
    }

    .link {
        color: #000;
        background-color: #3dfaff;
        transition: all 0.2s ease-out;
        padding: 10px 20px;
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid #3dfaff;
        font-size: 1rem;
    }

    .link:hover {
        color: #3dfaff;
        background-color: rgba(0,0,0,0);
    }
}