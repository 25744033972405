.Header {
    height: 105vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: linear-gradient(150deg, #131313, #212121);

    .borderLine {
        position: absolute;
        bottom: -25px;
        width: 1920px;
        height: 43px;
    }

    .particles {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .HeaderContent {
        margin-top: 40px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        max-width: 1140px;
        width: 100%;
        height: fit-content;
        position: relative;
        //transform-origin: center;
        //animation: rotate 10s ease-in-out infinite;

        .textWrapper {
            width: 75%;
            box-sizing: border-box;
            padding: 0 15px;
        }

        .text {
            position: relative;
            display: inline-block;
            width: 100%;
            background-color: rgba(0,0,0,0.6);
            padding: 70px 60px 80px 60px;
            box-sizing: border-box;
            
            .name {
                display: block;
                margin: 0 0 50px 0;

                h1 {
                    font-size: 3rem;
                    margin: 0;
                }

                .HeaderText {
                    font-size: 1.2rem;
                    padding: 0 0 0 10px;
                    text-transform: uppercase;
                    color: #0FBDE1;
                }

                .nameLine {
                    width: 97%;
                    height: 7px;
                    background-color: rgba(15, 203, 225, 0.63);
                    margin-left: 10px;
                    margin-top: 20px;
                    position: relative;

                    .highlight {
                        position: absolute;
                        width: 25%;
                        height: 100%;
                        background-color: #0FCBE1;
                        box-shadow: 0 1px 11px #0FE1DA;
                    }
                }
            }

            .info {
                padding: 0 0 0 10px;
                margin: 30px 0;

                .infoHighlight {
                    background-color: rgba(61, 125, 255, 0.35);
                    color: #fff;
                    padding: 2px 3px;
                }
            }

            .computerTextBottom {
                position: absolute;
                height: 40px;
                bottom: 0;
                right: 0;
                padding-right: 15px;
                text-align: right;
                width: 100%;
                color: #FF3D5D;
                text-transform: uppercase;
                font-size: 0.8rem;
                box-sizing: border-box;
                line-height: 40px;
            }
        }

        .imageWrapper {
            box-sizing: border-box;
            padding: 0 15px;
        }

        .image {
            background-color: rgba(0,0,0,0.6);
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            height: fit-content;

            .imageText {
                height: 30px;
                width: 100%;
                padding-left: 15px;
                box-sizing: border-box;
                color: #0fBDe1;
                border: 1px solid #0fBDe1;
                text-transform: uppercase;
                font-size: 0.8rem;
                line-height: 30px;
                font-family: Blender Pro Bold;
            }

            img {
                max-width: 100%;
                max-height: 300px;
                margin-top: 40px;
            }
        }

        .computerTextTop {
            position: absolute;
            height: 30px;
            top: 0;
            left: 0;
            padding-left: 15px;
            width: 100%;
            color: #0fBDe1;
            border: 1px solid #0fBDe1;
            text-transform: uppercase;
            font-size: 0.8rem;
            box-sizing: border-box;
            line-height: 30px;
            font-family: Blender Pro Bold;
        }

        .HeaderImage {
            width: 200px;
        }
    }
}

@media(max-width: 1025px) {
    .Header {
        height: auto;

        .HeaderContent {
            margin-top: 0;
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 70px;

            .textWrapper {
                width: 100%;
                margin-top: 20px;
            }

            .text {
                margin-bottom: 20px;
                padding: 50px 15px 40px 15px;

                .name {
                    text-align: center;
                    h1 {
                        font-size: 2rem;
                    }
                    .nameLine {
                        margin-left: auto;
                        margin-right: auto;
                        width: 95%;

                        .highlight {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }

            .image {
                margin-bottom: 20px;
            }
        }
    }
}

@keyframes rotate {

  0% {
    transform: perspective(700px) rotateY(2deg);
  }

  50% {
    transform: perspective(700px) rotateY(-2deg);
  }

  100% {
    transform: perspective(700px) rotateY(2deg);
  }
}