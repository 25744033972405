.About {
    background-color: #171717;
    position: relative;
    overflow-x: hidden;

    .borderLine {
        position: absolute;
        top: -25px;
        width: 1920px;
        height: 43px;
    }

    .AboutContent {
        margin: 0 auto;
        padding: 50px 15px 65px 15px;
        box-sizing: border-box;
        max-width: 1400px;

        .abouttext {
            max-width: 700px;
            margin: 0 auto 80px auto;
        }

        h3 {
            text-align: center;
            text-transform: uppercase;
        }
    }
}

:global .header-line {
    display: block;
    width: 40px;
    border: 1px dotted rgba(255,255,255,0.7);
    margin: 0 auto 25px auto;
}