.skill {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .hexagon {
        width: 40px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 60%;
            max-height: 60%;
        }
    }

    .name {
        margin-left: 15px;
        background-image: linear-gradient(to right, #20A39E, #171717);
        background-repeat: no-repeat;
        background-size: 80% 5%;
        background-position-y: bottom;
    }
}