@font-face {
  font-family: "Blender Pro Book";
  src: local('Blender Pro Book'), 
  url("../../assets/fonts/BlenderProBook/Blender Pro Book.ttf") format('truetype'),
  url("../../assets/fonts/BlenderProBook/Blender Pro Book.eot"),
  url("../../assets/fonts/BlenderProBook/Blender Pro Book.eot?#iefix") format('embedded-opentype'),
  url("../../assets/fonts/BlenderProBook/Blender Pro Book.woff2") format('woff2'),
  url("../../assets/fonts/BlenderProBook/Blender Pro Book.woff") format('woff'),
  url("../../assets/fonts/BlenderProBook/Blender Pro Book.svg#BlenderProBold") format('svg'),
}

@font-face {
  font-family: "Blender Pro Bold";
  src: local('Blender Pro Bold'), 
  url("../../assets/fonts/BlenderProBold/Blender Pro Bold.ttf") format('truetype'),
  url("../../assets/fonts/BlenderProBold/Blender Pro Bold.eot"),
  url("../../assets/fonts/BlenderProBold/Blender Pro Bold.eot?#iefix") format('embedded-opentype'),
  url("../../assets/fonts/BlenderProBold/Blender Pro Bold.woff2") format('woff2'),
  url("../../assets/fonts/BlenderProBold/Blender Pro Bold.woff") format('woff'),
  url("../../assets/fonts/BlenderProBold/Blender Pro Bold.svg#Blender Pro Bold") format('svg'),
}

.App {
  font-family: Blender Pro Book;
}

$title-blue: #3DFAFF;

h2 {
  color: $title-blue;
  border-color: $title-blue;
}

h3 {
  margin-bottom: 5px;
}