.projectsWrapper {
    background: linear-gradient(135deg, #2C2C2C, #171717);
    position: relative;
    overflow-x: hidden;

    .borderLine {
        position: absolute;
        top: -5px;
        width: 1920px;
        height: 26px;
        transform: rotateX(180deg);
    }
}

.projects {
    margin: 0 auto;
    padding: 50px 15px 80px 15px;
    box-sizing: border-box;
    max-width: 1200px;

    .overview {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 20px;
    }
}