.skills {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;

    .card {
        border: 2px dotted #FF3D5D;
        padding: 15px 30px;
        width: 220px;
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        position: relative;

        .cardtitle {
            font-family: Blender Pro Bold;
            text-transform: uppercase;
            font-size: 1rem;
            padding-bottom: 10px;
            color: #FF3D5D;
        }

        .line {
            display: block;
            width: 50px;
            height: 1px;
            background-color: #FF3D5D;
            margin-bottom: 30px;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.9);
            transition: background-color 0.3s ease;
            color: #000;
            display: none;
        }

        .overlay:hover {
            background-color: rgba(255,255,255,0);
            color: rgba(0,0,0,0);
        }
    }

    .card:before {
        content: "";
        position: absolute;
        bottom: -2px;
        right: -2px;
        border-width: 30px 0 30px 30px;
        border-style: solid;
        border-color: #FF3D5D transparent transparent #FF3D5D ;
        transform: rotate(180deg);
    }
}

@media (min-width: 576px) {
    .skills {
        justify-content: space-around;
    }
}