@font-face {
  font-family: "Blender Pro Book";
  src: local('Blender Pro Book'), 
  url("./assets/fonts/BlenderProBook/Blender Pro Book.ttf") format('truetype'),
  url("./assets/fonts/BlenderProBook/Blender Pro Book.eot"),
  url("./assets/fonts/BlenderProBook/Blender Pro Book.eot?#iefix") format('embedded-opentype'),
  url("./assets/fonts/BlenderProBook/Blender Pro Book.woff2") format('woff2'),
  url("./assets/fonts/BlenderProBook/Blender Pro Book.woff") format('woff'),
  url("./assets/fonts/BlenderProBook/Blender Pro Book.svg#Blender Pro Book") format('svg'),
}

@font-face {
  font-family: "Blender Pro Bold";
  src: local('Blender Pro Bold'), 
  url("./assets/fonts/BlenderProBold/Blender Pro Bold.ttf") format('truetype'),
  url("./assets/fonts/BlenderProBold/Blender Pro Bold.eot"),
  url("./assets/fonts/BlenderProBold/Blender Pro Bold.eot?#iefix") format('embedded-opentype'),
  url("./assets/fonts/BlenderProBold/Blender Pro Bold.woff2") format('woff2'),
  url("./assets/fonts/BlenderProBold/Blender Pro Bold.woff") format('woff'),
  url("./assets/fonts/BlenderProBold/Blender Pro Bold.svg#Blender Pro Bold") format('svg'),
}

html, body {
  margin: 0;
  font-size: 22px;
  color: #fff;
  background-color: #131313;
}

body {
  font-family: Blender Pro Book;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6  {
  font-family: Blender Pro Bold;
}

h2 {
  text-transform: uppercase;
  margin-top: 30px;
}

.full-page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: #131313;
}

.full-page-loader > .loader-logo {
  animation: 1.8s infinite heartbeat;
}

@keyframes heartbeat {
  0% { transform: scale(0.95); }
  25% { transform: scale(1); }
  50% { transform: scale(0.95); }
  75% { transform: scale(1); }
  100% { transform: scale(0.95); }
}