.navigationWrapper {
    position: absolute;
    width: 100%;
    z-index: 100;
    background-color: rgba(13,13,13,0.6);
}

.navigation {
    margin: 20px auto 0 auto; // MEDIA //
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    max-width: 1140px;

    justify-content: center; // MEDIA //
    flex-direction: column; // MEDIA //
    align-items: center;

    .imageDiv {
        display: flex;
        height: 100%;
        align-items: center;
    }

    img {
        height: 70px;
    }

    .links {
        display: flex;
        flex-direction: column; // MEDIA //
        text-align: center;
        margin-top: 20px; // MEDIA //
        
        .navLink {
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            margin: 5px 0; // MEDIA //
            font-size: 1.4rem;
            transition: all 0.2s ease-out;
            padding: 0 8px;
        }

        .navLink:hover {
            color: #000;
            background-color: #3dfaff;
        }
    }
}

@media(max-width: 1025px) {
    .navigation {
        display: none;
    }
}

@media (min-width: 576px) {
    .navigation {
        justify-content: space-between;
        flex-direction: row;
        height: 100px;
        margin: 0 auto;

        .links {
            flex-direction: row;
            margin-top: 0;

            .navLink { 
                margin: 0 0 0 40px;
            }
        }
    }
}