.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    background-color: #171717;
    position: relative;
    overflow-x: hidden;

    .borderLine {
        position: absolute;
        top: -29px;
        width: 1920px;
        height: 47px;
        transform: rotateX(180deg);
    }

    .emailLink {
        color: rgba(255,255,255,0.3);
        margin-bottom: 25px;
    }

    .logo {
        width: 70px;
        margin-bottom: 0px;
        opacity: 0.5;
    }

    .socials {
        img {
            width: 30px;
            margin: 10px;
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px dashed #171717;
            transition: all 0.2s ease-out;
        }

        img:hover {
            border-bottom-color: #3DFAFF;
            border-bottom-style: solid;
        }
    }

    .credits {
        margin-top: 20px;
    }

    .name {
        display: inline-block;
        font-family: Blender Pro Bold;
        background-color: #3DFF90;
        color: #000;
        font-size: 1.2rem;
        padding: 0 10px;
        margin-top: 5px;
    }
}