.projectpreview {
    width: 300px;
    height: 210px;
    margin: 20px 20px 20px 20px;
    box-sizing: border-box;
    position: relative;

    .title {
        width: 95%;
        height: 45px;
        background-color: rgba(5, 5, 5, 0.75);
        color: #3DFAFF;
        position: absolute;
        bottom: 30px;
        left: 35px;
        padding: 0px 15px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border: 1px solid #3DFAFF;
        transition: all 0.3s ease;

        .titleText {
            position: absolute;
            left: 15px;
        }

        .arrow {
            position: absolute;
            right: 15px;
            width: 15px;
            transform: rotate(180deg) translateX(0);
            opacity: 1;
            transition: all 0.3s ease;
        }
    }

    .corner {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-color: #272727 transparent transparent;
        border-style: solid;
        border-width: 17px 17px 0 0;
    }
}


.projectpreview:hover .title {
    left: 40px;
    background-color: rgba(5, 5, 5, 0.85);
    
    .arrow {
        transform: rotate(180deg) translateX(-100%);
        opacity: 0;
    }
}

@media (min-width: 576px) {
    .projectpreview {
        width: 480px;
        height: 320px;
    }
}
