.formWrapper {
    background-color: #282828;
    padding: 50px 0 100px 0;
    position: relative;
    overflow: hidden;

    .borderLine {
        position: absolute;
        top: -8px;
        width: 1920px;
        height: 26px;
        transform: rotateX(180deg)
    }

    .borderLineBottom {
        position: absolute;
        bottom: -29px;
        width: 1920px;
        height: 47px;
        transform: rotateX(180deg)
    }

    .form {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 750px;
        margin: 40px auto 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .formElement {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            padding-left: 5px;
            padding-right: 5px;
            box-sizing: border-box;
            width: 100%;
        }

        label {
            margin-bottom: 4px; // MEDIA //
            display: inline-block;
        }

        input {
            padding: 4px 15px;
            line-height: 36px;
            width: 100%;
            box-sizing: border-box;
        }

        input, textarea {
            background-color: #000;
            color: rgba(255,255,255,0.64);
            border: none;
            outline-color: #3DFF90;
        }
        input, textarea, input::placeholder, textarea::placeholder {
            font-family: Blender Pro Book;
            font-size: 1rem;
        }

        textarea {
            height: 160px; // MEDIA //
            width: 100%;
            line-height: 24px;
            padding: 8px 15px;
            box-sizing: border-box;
        }

        .errorMessage {
            margin-top: 4px;
            font-size: 0.9rem;
            color: #FF5E5B;
        }

        .buttonWrapper {
            position: relative;

            .button {
                font-family: Blender Pro Bold;
                text-transform: uppercase;
                border: 1px solid #3DFF90;
                font-size: 0.9rem;
                margin-left: 5px;
                display: inline-block;
                padding: 15px 35px;
                background-color: #282828;
                color: #3DFF90;
                cursor: pointer;
            }

            .button:hover {
                color: #000;
                background-color: #3DFF90;
            }

            .topCorner {
                transition: all 0.2s ease;
                position: absolute;
                left: 5px;
                bottom: 0;
                width: 0;
                height: 0;
                border-color: transparent transparent #282828 transparent;
                border-style: solid;
                border-width: 0 17px 17px 0;
            }
            
            .bottomCorner {
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
                height: 0;
                border-color: #282828 transparent #282828;
                border-style: solid;
                border-width: 17px 0 0 17px;
            }

            .buttonCornerTop, .buttonCornerBottom {
                position: absolute;
                width: 17px;
                height: 17px;
            }
            .buttonCornerTop {
                top: 0px;
                right: 0px;
            }
            .buttonCornerBottom {
                bottom: 0px;
                left: 5px;
            }
        }
    }

    .formMessage {
        width: 100%;
        display: block;
        font-size: 0.8rem;
        box-sizing: border-box;
        text-align: center;
        padding: 10px;
        margin-bottom: 30px;
    }

    .formSuccess {
        color: #37FF8B;
        border: 1px dotted#37FF8B;
    }

    .formFail {
        color: #FF5E5B;
        border: 1px dotted #FF5E5B;
    }

    .messages {
        max-width: 675px;
        margin: 0 auto;
        display: block;
        box-sizing: border-box;
        padding: 0 5px;
    }
}


@media (min-width: 992px) {
    .formWrapper {
        .form {
            .formElement {
                margin-bottom: 25px;
            }

            .formElement:nth-child(1), .formElement:nth-child(2) {
                width: 50%;
                display: inline-block;
            }

            label {
                margin-bottom: 10px; // MEDIA //
            }

            textarea {
                height: 300px; // MEDIA //
                line-height: 32px;
            }

            .errorMessage {
                margin-top: 10px;
            }
        }
    }
}